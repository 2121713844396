
import { defineComponent, ref } from "vue";
import userBanner from "@/components/common/UserBanner.vue";
import MenuTab from "@/components/common/MenuTab.vue";
import { calendarFormat } from "@/utils/dateFormat";
import list from "@/components/common/list.vue";
import BalanceItem from "@/components/common/BalanceItem.vue";
import AccountItem from "@/components/common/AccountItem.vue";
import api from "@/api/axios";
export default defineComponent({
  name: "Bill",
  components: {
    MenuTab,
    list,
    BalanceItem,
    AccountItem,
    userBanner
  },

  setup() {
    type Menu = "我的货款" | "我的余额";
    const menu: Menu[] = ["我的货款", "我的余额"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    const list = ref(null);
    api.get("/M/User").then((res): void => {
      list.value = res.data.obj.orderBanners;
    });
    return {
      menu,
      currentMenuItem,
      selectMenu,
      calendarFormat,
      list
    };
  },
});
